import request from "@/utils/request"
import {COMPANY_NAME} from "@/utils/config"

export function uploadImage(dir, file) {
  let formData = new FormData()
  formData.append('file', file)
  return request.post(`/upload/image/${dir}`, formData)
}

export function uploadVideo(dir, file) {
  let formData = new FormData()
  formData.append('file', file)
  return request.post(`/upload/video/${dir}`, formData)
}

export function organizationTree(use_base = false) {
  return new Promise((resolve, reject) => {
    request.get('/developers/all-and-distributor').then(res => {
      let data = []
      for (let i in res) {
        let children = []
        if (res[i].distributorList && res[i].distributorList.length > 0) {
          for (let j in res[i].distributorList) {
            children.push({
              type: 'distributor',
              origin_id: res[i].distributorList[j].id,
              id: 'distributor_' + res[i].distributorList[j].id,
              label: res[i].distributorList[j].name + ` (经销商)`,
              parent_id: res[i].id
            })
          }
        }
        let item = {
          type: 'developer',
          origin_id: res[i].id,
          id: 'developer_' + res[i].id,
          label: res[i].name + ` (开发商)`,
        }
        if (children.length) {
          item.children = children
        }
        data.push(item)
      }
      if (use_base) {
        resolve([{
          type: 'admin', origin_id: 'admin', id: 'admin', label: COMPANY_NAME, children: data, disabled: true
        }])
      } else {
        resolve(data)
      }
    }).catch(err => {
      reject(err)
    })
  })
}

export function organizationOptions() {
  return request.get('/developers/developers-and-distributor')
}

export function userPage(params) {
  return request.get('/user', {params: params})
}

export function userCreate(data) {
  return request.post('/user', data)
}

export function userCreateMulti(data) {
  return request.post('/user/list', data)
}

export function userUpdate(data) {
  return request.put('/user', data)
}

export function userDelete(id) {
  return request.delete(`/user/${id}`)
}

// 下载批量员工导入模板
export function downloadUserTemplate() {
  return request.get('/user/download', {responseType: 'blob'})
}

export function uploadUserTemplate(data) {
  return request.post('/user/import', data)
}


export function userInfo(id) {
  return request.get(`/user/id/${id}`)
}

export function userPermission(id) {
  return request.get(`/user-auth/${id}`)
}

export function userPermissionStore(data) {
  return request.post(`/user-auth`, data)
}

export function roleAll() {
  return request.get('/role/data-list')
}

export function deleteRoles(data) {
  return request.delete('/role/list?ids=' + data)
}

export function rolePage(params) {
  return request.get('/role', {params: params})
}

export function roleCreate(data) {
  return request.post('/role', data)
}

export function roleUpdate(data) {
  return request.put('/role', data)
}

export function roleInfo(id) {
  return request.get(`/role/id/${id}`)
}

export function roleMenus(id) {
  return request.get(`/menu/role/${id}`)
}

export function roleUserPage(id, params) {
  return request.get(`/role/users/${id}`, {params: params})
}

export function roleNotSyncUserAll(id) {
  return request.get(`/role/users/no/${id}`)
}

export function roleSyncUsers(id, data) {
  return request.post(`/role/users/and/${id}?userIds=${data.userIds}`)
}

export function roleClearUser(id, userId) {
  return request.post(`/role/clear/${id}/${userId}`)
}

export function roleSwitchClose(id) {
  return request.post(`/role/close/${id}`)
}

export function roleSetDefaultDeveloper(id) {
  return request.post(`/role/default/${id}`)
}

export function menuAll() {
  return request.get('/menu/all')
}

export function developerAndDistributorOptions() {
  return request.get('/developers/developers-and-distributor')
}

export function farmerAll() {
  return request.get('/farmer/data-list')
}

export function farmerPage(params) {
  return request.get('/farmer', {params: params})
}

export function farmerCreate(data) {
  return request.post('/farmer', data)
}

export function farmerRoofCreate(data) {
  return request.post('/farmer/gsy', data)
}

export function farmerRoofUpdate(data) {
  return request.put('/farmer/gsy', data)
}

export function farmerUpdate(data) {
  return request.put('/farmer', data)
}

export function farmerInfo(id) {
  return request.get(`/farmer/id/${id}`)
}

export function farmerDelete(id) {
  return request.delete(`/farmer/${id}`)
}

export function salesmanPage(params) {
  return request.get('/user/salesman', {params: params})
}

export function farmerDispatchOrder(data) {
  return request.post('/word-order/check', data)
}

export function farmerExport(params) {
  return request.get('/farmer/export', {params: params, responseType: 'blob'})
}


export function developerAll() {
  return request.get('/developers/data-list') //有权限
}

export function developerOptions() {
  return request.get('/developers/all') //有权限
}

export function developerPage(params) {
  return request.get('/developers', {params: params})
}

export function developerInfo(id) {
  return request.get(`/developers/id/${id}`)
}

export function developerCreate(data) {
  return request.post('/developers', data)
}

export function developerUpdate(data) {
  return request.put('/developers', data)
}

export function uploadFile(file) {
  let formData = new FormData()
  formData.append('file', file)
  return request.post('/file/upload', formData)
}

export function distributorAll() {
  return request.get('/distributor/data-list')
}

export function distributorPage(params) {
  return request.get('/distributor', {params: params})
}

export function distributorInfo(id) {
  return request.get(`/distributor/id/${id}`)
}

export function distributorCreate(data) {
  return request.post('/distributor', data)
}

export function distributorUpdate(data) {
  return request.put('/distributor', data)
}

export function configurePage(params) {
  return request.get('/configure/page', {params: params})
}

export function configureCreate(data) {
  return request.post('/configure', data)
}

export function configureUpdate(data) {
  return request.put('/configure', data)
}

export function configureInfo(id) {
  return request.get(`/configure/id/${id}`)
}

export function configureChangeState(id, state) {
  return request.put(`/configure/state/${id}/${state}`)
}


export function configureExport(params) {
  return request.get('/configure/export', {params: params, responseType: 'blob'})
}


export function roofTypeAll() {
  return request.get('/roof-type/data-list', {params: {farmerType: 0}})
}

export function stationPage(params) {
  return request.get('/word-order/page/examine', {params: params})
}

export function stationReview(id, state) {
  return request.put(`/word-order/examine/${id}/${state}`)
}

export function stationCheck(id, state, msg) {
  return request.put(`/project/examine/${id}?state=${state}&msg=${msg}`)
}

export function stationExport(params) {
  return request.get('/word-order/export/examine', {params: params, responseType: 'blob'})
}

export function connectionPage(params) {
  return request.get('/connection', {params: params})
}

export function saveConnection(data) {
  return request.put('/connection', data)
}

export function connectionExport(params) {
  return request.get('/connection/export', {params: params, responseType: 'blob'})
}

export function orderPage(params) {
  return request.get('/word-order/page', {params: params})
}

export function orderExport(params) {
  return request.get('/word-order/export/word-order', {params: params, responseType: 'blob'})
}

export function orderClose(id) {
  return request.put(`/word-order/close/${id}`)
}

export function orderTransfer(id, userId) {
  return request.post(`/word-order/transfer/${id}/${userId}`)
}

export function contractPage(params) {
  return request.get('/signature', {params: params})
}

export function contractAgain(signatureId) {
  return request.post(`/signature/flow/again/${signatureId}`)
}

export function contractTemplateAll() {
  return request.get('/signature/templates')
}

export function contractFlow(id, type, data) {
  return request.post(`/signature/flow/${id}/${type}`, data)
}

export function contractConsole(params) {
  return request.get('/signature/console', {params: params})
}

export function adminProjectPage(params) {
  return request.get('/project/admin', {params: params})
}

export function deUserPage(params) {
  return request.get('/de-user', {params: params})
}

export function billPage(params) {
  return request.get('/bill/new/page', {params: params})
}

export function billExport(params) {
  return request.get('/bill/export', {params: params, responseType: "blob"})
}

export function billSync(developersId) {
  return request.post('/bill/synchronization?developersId=' + developersId)
}


export function deUserInfo(id) {
  return request.get(`/de-user/id/${id}`)
}

export function deUserCreate(data) {
  return request.post('/de-user', data)
}

export function deUserUpdate(data) {
  return request.put('/de-user', data)
}

export function seeTransferUpdate(params) {
  return request.get(`/de-user/repayment/see/transfer`, {params: params})
}

export function seeTransferQuery(params) {
  return request.get(`/apis/see/transfer`, {params: params})
}

export function seeTransferInfo(id) {
  return request.get(`/de-user/repayment/list/${id}`)
}

export function deUserReviewPage(params) {
  return request.get('/de-user/review/page', {params: params})
}

export function deUserRepaymentPost(ids) {
  return request.post(`/apis/repayment?userIds=${ids}`)
}

export function deUserRepaymentPostAll() {
  return request.post('/apis/repayment/all')
}

export function deUserRepaymentUpdate(data) {
  return request.put('/de-user/repayment/update', data)
}

// 提醒实名
export function realNameAuth(userId) {
  return request.get(`/signature/authentication/console/${userId}`)
}

//e签宝个人授权
export function psnEmpowerAuth(userId) {
  return request.get(`/signature/psn-empower/${userId}`)
}

//e签宝企业授权
export function orgEmpowerAuth(developerId) {
  return request.get(`signature/org-empower/${developerId}`)
}


// 获取企业印章列表
export function orgSealPage(developerId, params) {
  return request.get(`/signature/org-own-seals-list/${developerId}`, {params})
}

//印章授权
export function orgSealAuth(developersId, sealId) {
  return request.get(`/signature/org-seals/${developersId}?sealId=${sealId}`)
}


// 电子签列表
export function signDataPage(params) {
  return request.get('/developers/sign-data', {params: params})
}

// 进入控制台
export function signatureWithdeveloperId(developersId) {
  return request.get(`/signature/console/${developersId}`)
}

// 统计页
export function dashBoardData() {
  return request.get('/statistics/data')
}

// 统计页-全部导出
export function dashBoardExportAll() {
  return request.get('/statistics/export/all', {responseType: "blob"})
}

// 统计页-批量导出
export function dashBoardExport(params) {
  return request.get('/farmer/statistics/export', {params: params, responseType: "blob"})
}

//  统计页-右边前两个列表接口
export function dashBoardTaskList(state = '') {
  return request.get('/word-order/page', {
    params: {
      order_by_column: 'id',
      order_by_direction: 'desc',
      page: 1,
      limit: 8,
      state: state
    }
  })
}

//  统计页-右边前两个列表接口
export function dashBoardHuazhaunList() {
  return request.get('/de-user/review/page', {
    params: {
      order_by_column: 'id',
      order_by_direction: 'desc',
      page: 1,
      limit: 8
    }
  })
}

